import {
    Box,
    Button,
    HStack,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Stack,
  } from '@mybridge/ui';
  import { forwardRef, useEffect, useRef } from 'react';
  import { useDisclosure } from '@mybridge/ui';
  import { CloseBlueIcon } from '@mybridge/icons';
  import { useOutsideClick } from '@mybridge/ui';
  import styles from './filter-keyword-popover.module.scss';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
  export const FilterPopoverPopper = forwardRef(
    ({ trigger, children, onPopClose, withForm, isFetching, placement, ...props }, ref) => {
      const { onOpen, onClose, isOpen } = useDisclosure();
      const refElem = useRef();
      const { t } = useTranslator();
      const initialFocusRef = useRef();
      useEffect(() => {
        if (isFetching) {
          onOpen();
        }
      }, [isFetching]);
  
    //   useOutsideClick({
    //     ref: refElem,
    //     handler: () => {onClose(); onPopClose(false);},
    //   })
  
      return (
        <Box as="span" ref={refElem} w="100%">
          <Popover
            placement={placement ? placement : "bottom-start"}
            initialFocusRef={initialFocusRef}
            autoFocus={false}
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            
          >
            {/* {({ isOpen, onClose }) => ( */}
            <>
              <PopoverTrigger ref={initialFocusRef}>{trigger}</PopoverTrigger>
              <Portal>
                <PopoverContent w="auto" minW="300px" position="relative">
                <CloseBlueIcon
                  as="button"
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    zIndex: 10,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    console.log('CloseBlueIcon clicked');
                    onClose();
                  }}
                />
                  <PopoverBody shadow="md" p={0}>
                    <Stack>
                      <Stack>{children}</Stack>
                      {withForm && (
                        <HStack
                            pr={"20px"}
                            pb={"20px"}
                            style={{display: 'flex', justifyContent: 'flex-end'}}
                        >
                          <Button
                            variant="secondary"
                            onClick={() => {
                              onClose();
                              onPopClose(false);
                            }}
                            size="md"
                            type="button"
                          >
                            {t('buttons.cancel') || "Cancel"}
                          </Button>
                          <Button type="submit" variant="primary" size="md">
                            {t('searchPage.showResults') || "Show Results"}
                          </Button>
                        </HStack>
                      )}
                    </Stack>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </>
            {/* )} */}
          </Popover>
        </Box>
      );
    }
  );